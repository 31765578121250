import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function HospitalityPage() {
  return (
    <Layout pageName="industry">
      <SEO
        title="End to End Technology Solutions for the Hospitality Industry"
        description="WYN Technologies offers end-to-end technology solutions to hotels, restaurants, resorts, and entertainment venues of all sizes. Contact us today to get your Quote."/>   
    <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Hospitality</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for the Hospitality Industry</GroceryTitle>
              <p>Hospitality is a broad industry which includes everything from hotels, to restaurants, to resorts, to tourism-related services, to amusement parks, entertainment venues, recreational venues and experiences, cruises, and more. This complex industry is usually thought to consist of five major segments: food, lodging, travel, tourism, and recreation.</p>
              <p>But no matter which kind of hospitality business you lead and operate, there are many needed business technology solutions which can improve your business for you, your employees, and most of all, your customers. And the really great news for you? <strong>WYN Technologies</strong> specializes in providing and installing essential technology solutions for hospitality ventures of all stripes.</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Essential Services We Offer for Hospitality-Related Businesses</GroceryIndustryTitle>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-2.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">POS System Installations</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Any hospitality business needs to be able to process customer transactions in as swift and efficient a manner as possible. Old-school cash registers and legacy point-of-sale systems can really limit you in that regard. Modern POS system technology offers many advantages and benefits which provide some real ROI for your business. You can more effectively manage sales, issue receipts in printed or electronic form, stay on top of current inventory and ordering needs, and much more. And WYN offers many great POS solutions which help to make your customers’ last impression of you be a positive one!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList> 
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">VoIP Phone Systems</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Let’s say that you own or manage a hotel. In many ways, your front desk is the control center for your business – from the customer’s perspective, anyway. How effective and efficient is your phone system? Upgrading to a VoIP-based phone system makes a lot of sense, for many reasons. It affords several advanced features you won’t have access to otherwise, and VoIP can save you money on calling costs, too. Ready to improve call quality and unify your whole enterprise at the same time? Let the pros at WYN Technologies deliver a better communications solution for you today!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
                  <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Data Cabling, including Fiber</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Pretty much every American hotel has some type of internet/data network in place, as do other hospitality-related businesses. The fact of the matter, however, is that many of these existing network infrastructures have become pretty outdated. Fiber optic networks have been shown to be a much better solution, for many reasons. Fiber optic cabling provides for significantly data transmission speeds (as opposed to older copper cabling solutions), with essentially no embedded interference issues. Fiber optics significantly increase effective bandwidth too, meaning you can offer much more effective internet and data connections for individual rooms too, while not having to sacrifice any speed transmission performance. Ready to learn more about the possibilities with fiber optics? Contact the pros at WYN today!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">WiFi Networks & Access Points</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Let’s face it – all of your guests love having convenient WiFi access, and in fact have come to expect you to provide it. How’s the WiFi network performing in your hospitality business? WYN Technologies can perform effective heat mapping services to help you troubleshoot any dead spots, along with any areas that would benefit from having new access points added. In addition, we’re experts at designing and installing new wireless networks for facilities and properties of all sizes and designs. Is it time to upgrade the WiFi for your hospitality enterprise? WYN can make that happen for you!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
            <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">New Rollouts, Remodels, and Location Moves, Adds, & Changes</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>WYN is happy to provide you with a one-off installation of any business technology solution you may need, but we offer much more than just that. We can serve as your technology install consultant for any new hospitality business site or remodel, and we can help you to manage multiple location rollouts, as well. Need to have some legacy equipment or cabling de-installed? We can take care of that, too. Whatever your particular project entails, WYN Technologies knows how to help you meet your project deadlines, while also exceeding your expectations!</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">IT Support Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Wish you had some ongoing business technology IT support for your hospitality business? Well, you can, by simply partnering with WYN Technologies! You can give us a call whenever you may need us, but we offer some specific service-after-the-sale contracts for your benefit, as well. You can enlist us to help keep all your equipment running smoothly for you, ensuring that you’ll have one less headache to worry about while you’re running your business.</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
                <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Equipment Staging, Warehousing, & Depot Repair Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>WYN has a complete staging facility for getting new equipment installations set up and ready-to-go for you. We’ll get everything configured ahead of time, thus making the install process go that much more smoothly. We also have a large, security-monitored warehouse available if you have some other equipment storage needs. Got some faulty equipment that needs replacing? You can also take advantage of our depot repair services, and be able to benefit from choosing a refurbished equipment option versus having to purchase a brand-new replacement.</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
                  </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Content Heading</h2> */}
              </MarkdownContentTitle>
            {/*  <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
               <MarkdownContent>
                  <h2>Access Control Technology for the Hospitality Industry</h2>
                  <p>When it comes to leading and managing a hotel or other hospitality business, having secure access control technology in place is an absolute necessity. You need an effective way to manage who can access your building from the outside and when, and your guests appreciate the convenience of having a keyless access solution for getting in and out of their respective rooms. Whether you prefer to offer proximity cards, keychain fobs, mobile device connectivity, or biometric security features, WYN Technologies can provide and install any type of hardware & software configuration you may need.</p>
               </MarkdownContent>
               <MarkdownContent>
                    <h2>Choose WYN Technologies for All Your Hotel and Other Hospitality Business Technology Needs</h2>
                  <ul>
                    <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At WYN, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility, before we ever come out to install your new equipment.</p>
                    </li>
                    <li>
                    <strong>Only the Highest-Quality Products & Services </strong>
                    <p>WYN Technologies only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                    </li>
                    <li>
                    <strong>We Know How to Work Within Your Budget </strong>
                    <p>WYN is ultimately here to help your business succeed. We understand corporate budgets & finances, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                    </li>
                    <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, WYN is committed to being here for you for the long run. We actually answer the phone when you call, and we’ll respond promptly to your needs 24/7.</p>
                    </li>
                    </ul>
                </MarkdownContent>
             <MarkdownContent>
                <h2>Got Questions? Ready to Get Started?</h2>
                <p>Need to plan some business technology upgrades for your hotel or hospitality business? WYN Technologies is happy to provide you with a free initial site evaluation and consultation. Got more questions? Ready to get started? Connect with us today at <a href="tel:3368990555">(336) 899-0555</a>, and let’s get started on making the best business technology plan for your business!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default HospitalityPage
